<template>
	<div class="login register">
			<el-form class="login-box register-box">
				<h3 class="login-title">设置新密码</h3>
				<el-form-item prop="telephone">
					<i class="iconfont">&#xe615;</i>
					<el-input name="mobile" type="text" placeholder="请输入手机号" autocomplete="off" autofocus="true"
						v-model="mobile" />
				</el-form-item>

				<el-form-item prop="captchaCode" class="captchaCode">
					<i class="iconfont">&#xe623;</i>
					<el-input name="captchaCode" type="text" placeholder="请输入图形验证码"
						v-model="captchaCode" />
					<el-image class="image" :src="captcha.base64" @click="getCaptcha()"></el-image>
				</el-form-item>

				<el-form-item prop="smscode" class="code">
					<i class="iconfont">&#xe623;</i>
					<el-input name="smsCode" v-model="smsCode" placeholder="请输入验证码"></el-input>
					<el-button class="send-code" type="warning" size="mini" :disabled='smsState'
					@click="handelSmsCaptcha()">
						{{!smsState ? '获取验证码' : '重新发送'+times+'秒'}}
					</el-button>
				</el-form-item>

				<el-form-item prop="password">
					<i class="iconfont">&#xe630;</i>
					<el-input name="password" type="password" placeholder="请设置密码" v-model="password" autocomplete="off" />
				</el-form-item>

				<el-form-item prop="checkPass">
					<i class="iconfont">&#xe630;</i>
					<el-input name="checkPass" type="password" placeholder="请重复密码" v-model="checkPass" autocomplete="off">
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-button class="submit-btn" type="danger" v-on:click="handleLogin">提交</el-button>
				</el-form-item>
			</el-form>

	</div>
</template>

<script>
	import * as Verify from '@/utils/verify'
	import * as LoginApi from '@/api/login'

	// 倒计时时长(秒)
	const times = 60

	// 表单验证场景
	const GET_CAPTCHA = 10
	const SUBMIT_LOGIN = 20

	export default {
		data() {
			return {
				// 图形验证码信息
				captcha: {},
				// 短信验证码发送状态
				smsState: false,
				// 倒计时
				times,
				// 手机号
				mobile: '',
				// 图形验证码
				captchaCode: '',
				// 短信验证码
				smsCode: '',
				password: '',
				checkPass: '',
				isParty: false,
				// 第三方用户信息数据
				partyData: [],
				// 对话框显示和隐藏
				dialogVisible: true
			};
		},
		created() {
			// 获取图形验证码
			this.getCaptcha()
		},
		methods: {
			handleClose(done) {
				done();
				this.$router.push("/");
			},
			// 获取图形验证码
			getCaptcha() {
			  const app = this
			  LoginApi.captcha()
			    .then(result => app.captcha = result.data)
			},

			// 点击发送短信验证码
			handelSmsCaptcha() {
			  const app = this
			  if (!app.isLoading && !app.smsState && app.formValidation(GET_CAPTCHA)) {
			    app.sendSmsCaptcha()
			    app.getCaptcha()
			  }
			},

			// 表单验证
			formValidation(scene = GET_CAPTCHA) {
			  const app = this
			  // 验证获取短信验证码
			  if (scene === GET_CAPTCHA) {
			    if (!app.validteMobile(app.mobile) || !app.validteCaptchaCode(app.captchaCode)) {
			      return false
			    }
			  }
			  // 验证提交登录
			  if (scene === SUBMIT_LOGIN) {
			    if (!app.validteMobile(app.mobile) || !app.validteSmsCode(app.smsCode) || !app.validatePwd(app.password, app.checkPass)) {
			      return false
			    }
			  }
			  return true
			},

			// 验证手机号
			validteMobile(str) {
			  if (Verify.isEmpty(str)) {
				this.$message.error('请先输入手机号')
			    return false
			  }
			  if (!Verify.isMobile(str)) {
				this.$message.error('请输入正确格式的手机号')
			    return false
			  }
			  return true
			},

			// 验证图形验证码
			validteCaptchaCode(str) {
			  if (Verify.isEmpty(str)) {
				this.$message.error('请先输入图形验证码')
			    return false
			  }
			  return true
			},

			// 验证短信验证码
			validteSmsCode(str) {
			  if (Verify.isEmpty(str)) {
				this.$message.error('请先输入短信验证码')
			    return false
			  }
			  return true
			},

			// 验证密码
			validatePwd(pwd, confirmPwd) {
			  if (Verify.isEmpty(pwd)) {
				this.$message.error('请先输入密码')
			    return false
			  }
			  if (pwd.length < 6){
				  this.$message.error('密码不能小于6位')
				  return false
			  }
			  if(pwd != confirmPwd){
				  this.$message.error('两次输入密码不一致')
				  return false
			  }
			  return true
			},

			// 请求发送短信验证码接口
			sendSmsCaptcha() {
			  const app = this
			  app.isLoading = true
			  LoginApi.sendSmsCaptcha({
			      form: {
			        captchaKey: app.captcha.key,
			        captchaCode: app.captchaCode,
			        mobile: app.mobile
			      }
			    })
			    .then(result => {
			      // 显示发送成功
				  this.$message({
				    message: result.message,
				    type: 'success'
				  })
			      // 执行定时器
			      app.timer()
			    })
			    .finally(() => app.isLoading = false)
			},

			// 执行定时器
			timer() {
			  const app = this
			  app.smsState = true
			  const inter = setInterval(() => {
			    app.times = app.times - 1
			    if (app.times <= 0) {
			      app.smsState = false
			      app.times = times
			      clearInterval(inter)
			    }
			  }, 1000)
			},

			// 点击登录
			handleLogin() {
			  const app = this
			  if (!app.isLoading && app.formValidation(SUBMIT_LOGIN)) {
			    app.submitLogin()
			  }
			},

			// 确认登录
			submitLogin() {
			  const app = this
			  app.isLoading = true
			  LoginApi.login({
				  form: {
					  smsCode: app.smsCode,
					  mobile: app.mobile,
					  password: app.password,
					  isParty: app.isParty,
					  partyData: app.partyData,
				  }
			    })
			    .then(response => {
					console.log(response)
			      // localStorage.setItem('token', response.data.token)
			      // localStorage.setItem('userId', response.data.userId)
			      // localStorage.setItem('mobile', this.mobile)
			      this.$message({
			      	message: '修改成功，请重新登录！',
			      	type: 'success'
			      });
                    this.$router.push("/login")
			    })
			    .finally(() => app.isLoading = false)
			}

		}

	}
</script>

<style lang="scss">
	@import "../../assets/css/login.scss"
</style>
